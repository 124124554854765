import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Components
// import HeaderPeopleOrg from 'Components/Organisms/Personas/Header'
// import FooterPeopleOrg from 'Components/Organisms/Personas/Footer'
// import C23PeopleOrg from 'Components/Organisms/Personas/C23'
// import C25PeopleOrg from 'Components/Organisms/Personas/C25'
// import C08PeopleOrg from './../../../Components/Organisms/Personas/C08'
import C08V3PeopleOrg from './../../../Components/Organisms/Personas/C08/v3'
import { URL_WEB_SERVICE_LOCAL_STORAGE } from '../../../constants/general'
import { HttpClient } from '../../../actions/HttpClient';
import { CategoriesService, ChannelsService, PlanPlanesService } from '../../../actions/HttpCalls';

const httpClient = new HttpClient();
const categoriesService = new CategoriesService(httpClient);
const channelsService = new ChannelsService(httpClient);
const planPlanesService = new PlanPlanesService(httpClient);

function TituloModulo(props) {
    const {
        titulo,
    } = props
    return (
        <section style={{ padding: 0, backgroundColor: '#999' }}>
            <div className="container" style={{ position: 'relative' }} >
                <h1 style={{ fontSize: '20px', fontWeight: '600', padding: '5px 10px', color: '#fff', zIndex: 1 }}>
                    {titulo}
                </h1>
            </div>
        </section>
    )
}

TituloModulo.protoType = {
    titile: PropTypes.string,
}

function HomePeopleContainerUi(props) {
    // const {
    //     dataCompare
    // } = props

    const [dataCategoriasBk, setDataCategoriasBk] = useState([])
    const [dataChannelsBk, setDataChannelsBk] = useState([])
    const [dataPlanPlanesBk, setDataPlanPlanesBk] = useState([])

    const getValuesDefault = async () => {
        if (URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1) {
            const channels = channelsService.getChannelsItems()
            const planPlanes = planPlanesService.getPlanPlanesItems()
            Promise.all([channels, planPlanes]).then(([canales, planPlanes]) => {
                setDataChannelsBk(canales)
                setDataPlanPlanesBk(planPlanes)
            });

            const categories = await categoriesService.getCategoriesItems()
            setDataCategoriasBk(categories)

            console.log("channels", channels)
            console.log("categories", categories)
            // console.log("cplanes", cplanes)
        }
    }

    useEffect(() => {
        getValuesDefault();
        // eslint-disable-next-line
    }, [])

    return (
        <>

            {/* <HeaderPeopleOrg /> */}

            {/* <TituloModulo 
                titulo='Personas - C08'
            /> */}
            {/* <C08PeopleOrg /> */}
            {
                URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 ?
                    dataChannelsBk.length > 0 ?
                        <C08V3PeopleOrg
                            dataChannelsBk={dataChannelsBk}
                            dataCategoriasBk={dataCategoriasBk}
                            dataPlanPlanesBk={dataPlanPlanesBk}
                        /> : null
                    :
                    <C08V3PeopleOrg
                        dataChannelsBk={dataChannelsBk}
                        dataCategoriasBk={dataCategoriasBk}
                        dataPlanPlanesBk={dataPlanPlanesBk}
                    />
            }

            {/* <TituloModulo 
                titulo='Personas - C23'
            />
            <C23PeopleOrg />

            <TituloModulo 
                titulo='Personas - C25'
            />
            <C25PeopleOrg
                dataCompare={dataCompare}
            /> */}

            {/* <FooterPeopleOrg /> */}
        </>
    )
}

// HomePeopleContainerUi.propTypes = {

// }

export default HomePeopleContainerUi
